import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmA from "src/components/nonbm2"
import CtaDisclosureCb2 from "src/components/ctadisclosurecb2"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const prdbestData = import("./data/data.json");

export default function nonbmclearbar() {

  let css;
  let module = <CtaDisclosureCb2></CtaDisclosureCb2>;
  if(isChrome() || isIeEdge() || isFirefox()){
    module = <CtaDisclosureCb2></CtaDisclosureCb2>;
  }
  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
  background:#fff;
}

main {
background: url("/img/CookiesPiesBG.jpg") no-repeat;
background-size:cover;
}
.nonbma-ez-bf-module--nonbmaBf .nonbma-ez-bf-module--freedom {
  background: #eeeeee;
}
`}
   </style>

   <style type="text/css">
        {css}
            </style>

    <title>Easy Print - Easily Print Any Page!</title></Helmet>
      <NonbmA data={prdbestData}>{module}</NonbmA>
    </HomepageLayout>
  )
}
